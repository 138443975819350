import React, { useRef } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './mappage.scss';

import CarouselPages from '../../components/CarouselPages/CarouselPages';
import Footer from '../../components/Footer/Footer';
import { useLanguage } from '../../LanguageContext';
import { Helmet } from 'react-helmet';

import MapArtworkComponent from '../../components/MapArtwork/MapArtwork';
import { FaHandPointDown } from "react-icons/fa";

function MapPage() {
  const { selectedLanguage, handleLanguageChange } = useLanguage();
  const rowRef = useRef(null); // Riferimento alla riga successiva

  const scrollToNextRow = () => {
    if (rowRef.current) {
      rowRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const handleButtonClick = () => {
    scrollToNextRow();
  };
  

  return (
    <>
      <Helmet>
        {/* Titolo */}
        <title>Bisanzio Svelata - Mappa</title>

        {/* Meta Tags */}
        <meta name="description" content="Esplora i segreti di Bisanzio con Bisanzio Svelata - un progetto di ricerca dedicato a svelare i misteri dell'Impero Bizantino." />
        <meta name="keywords" content="Bisanzio, Bisanzio Svelata, progetto di ricerca, storia, misteri, Impero Bizantino" />

        {/* Open Graph / Facebook */}
        <meta property="og:title" content="Bisanzio Svelata - Mappa" />
        <meta property="og:description" content="Esplora i segreti di Bisanzio con Bisanzio Svelata - un progetto di ricerca dedicato a svelare i misteri dell'Impero Bizantino." />
        <meta property="og:image" content="https://www.bisanziosvelata.it/bisanziosvelata.png" />
        <meta property="og:url" content="https://www.bisanziosvelata.it/map" />
        <meta property="og:type" content="website" />

        {/* Twitter */}
        <meta property="twitter:title" content="Bisanzio Svelata - Mappa" />
        <meta property="twitter:description" content="Esplora i segreti di Bisanzio con Bisanzio Svelata - un progetto di ricerca dedicato a svelare i misteri dell'Impero Bizantino." />
        <meta property="twitter:image" content="https://www.bisanziosvelata.it/bisanziosvelata.png" />
        <meta property="twitter:url" content="https://www.bisanziosvelata.it/map" />

        {/* URL Canonico */}
        <link rel="canonical" href="https://www.bisanziosvelata.it/map" />
      </Helmet>
      
      <Container fluid className="bg-container">
        <Row className="justify-content-center align-items-center rowTitleO">
        <Col md={12} sx={12} lg={12} className='homeScritta'>
            
            <Col md={12} sx={12} lg={12}>
              <h1 className="titleO">
                {selectedLanguage === 'it' ? 'Mappa' : 'Map' }
              </h1>
              <h2 className="projectBriefDesc" style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'it'
                  ? "La mappa interattiva mostra la posizione esatta di tutti gli oggetti inclusi nel database. Cliccando su un segnaposto azzurro, compariranno nel box a sinistra tutte le opere esposte al pubblico in quella sede e sarà possibile accedere direttamente alle schede ad esse relative. Le maschere di ricerca permettono inoltre di effettuare indagini tematiche più approfondite."
                  : "The interactive map shows the exact locations of all objects included in the database. Click on a blue marker to see all the exhibited objects listed in the box on the left, with direct links to their records. You can also use search filters for more specific results."}
              </h2>
            </Col>
            {/* <Col md={4} sx={4} lg={4}>
              <CarouselPages />
            </Col> */}
          </Col>
        </Row>
        <div ref={rowRef}>
          <Row className="justify-content-center align-items-center">
              <Col md={12} className="text-center m-5">
                <MapArtworkComponent />
              </Col>
          </Row>
        </div>
      </Container>
      <Footer />
    </>
  );
}

export default MapPage;
