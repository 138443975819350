import { Carousel } from "react-bootstrap";
import React from "react";
import './carousel.css';

const PageCarousel = () => {
  const images = [
    "https://www.bisanziosvelata.it/logo512.png",
    "https://www.bisanziosvelata.it/c_01.jpg",
    "https://www.bisanziosvelata.it/c_02.jpg",
    "https://www.bisanziosvelata.it/c_03.jpg",
    "https://www.bisanziosvelata.it/c_04.jpg",
    "https://www.bisanziosvelata.it/c_05.jpg",
    "https://www.bisanziosvelata.it/c_06.jpg",
  ];

  return (
    <Carousel className='carouselCustom' fade interval={5000} controls={false} indicators={false}>
      {images.map((image, index) => (
        <Carousel.Item key={index} className="carouselcustomItem">
          <img
            className="d-block w-80 carouselImage3"
            src={image}
            alt={`Bisanzio Svelata Image ${index + 1}`}
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default PageCarousel;
