import './contactpage.scss';
import React from 'react';
import { Container, Row, Col,  Image } from 'react-bootstrap';
import CarouselPages from '../../components/CarouselPages/CarouselPages';
import Footer from '../../components/Footer/Footer';
import { Helmet } from 'react-helmet';
import { useLanguage } from '../../LanguageContext';
import ContactForm from '../../components/ContactForm/ContactForm';

function ContactPage() {
  const { selectedLanguage } = useLanguage();

  return (
    <>
    <Helmet>
      {/* Titolo / Title */}
      <title>Bisanzio Svelata - Contatti / Contacts</title>

      {/* Meta Tags */}
      <meta name="description" content="Esplora i segreti di Bisanzio con Bisanzio Svelata - un progetto di ricerca dedicato a svelare i misteri dell'Impero Bizantino. / Explore the secrets of Byzantium with Bisanzio Svelata - a research project dedicated to uncovering the mysteries of the Byzantine Empire." />
      <meta name="keywords" content="Bisanzio, Bisanzio Svelata, progetto di ricerca, storia, misteri, Impero Bizantino / Byzantium, Bisanzio Svelata, research project, history, mysteries, Byzantine Empire" />

      {/* Open Graph / Facebook */}
      <meta property="og:title" content="Bisanzio Svelata - Staff" />
      <meta property="og:description" content="Esplora i segreti di Bisanzio con Bisanzio Svelata - un progetto di ricerca dedicato a svelare i misteri dell'Impero Bizantino. / Explore the secrets of Byzantium with Bisanzio Svelata - a research project dedicated to uncovering the mysteries of the Byzantine Empire." />
      <meta property="og:image" content="https://www.bisanziosvelata.it/bisanziosvelata.png" />
      <meta property="og:url" content="https://www.bisanziosvelata.it/staff" />
      <meta property="og:type" content="website" />

      {/* Twitter */}
      <meta property="twitter:title" content="Bisanzio Svelata - Staff" />
      <meta property="twitter:description" content="Esplora i segreti di Bisanzio con Bisanzio Svelata - un progetto di ricerca dedicato a svelare i misteri dell'Impero Bizantino. / Explore the secrets of Byzantium with Bisanzio Svelata - a research project dedicated to uncovering the mysteries of the Byzantine Empire." />
      <meta property="twitter:image" content="https://www.bisanziosvelata.it/bisanziosvelata.png" />
      <meta property="twitter:url" content="https://www.bisanziosvelata.it/staff" />

      {/* URL Canonico / Canonical URL */}
      <link rel="canonical" href="https://www.bisanziosvelata.it/staff" />
    </Helmet>
    
    <Container fluid className="bg-container">
        <Row className="justify-content-center align-items-center rowTitleO">
          <Col md={12} sx={12} lg={12} className='homeScritta'>
            
            <Col md={12} sx={12} lg={12}>
              <h1 className="titleO">
              {selectedLanguage === 'it' ? 'Contatti' : 'Contacts'}
              </h1>
              <h2 className="projectBriefDesc" style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'it' 
                  ? 'Per richieste di informazioni o segnalazioni, si prega di compilare il modulo sottostante.'
                  : 'For information requests or to report an issue, please fill out the form below'}
              </h2>
       
            </Col>
          {/* <Col md={4} sx={4} lg={4}>
            <CarouselPages />
          </Col> */}
          </Col>
      </Row>
      <Row className="justify-content-center align-items-center p-3">
      <Col md={6} style={{alignSelf:"center"}}>
        <ContactForm />
      </Col>
      <Col md={6} style={{alignSelf:"center", textAlign: 'center'}}>
        <Row style={{alignItems:"center"}}>
          <Col>
              <a className = "linkUnivB" href="https://www.uniroma1.it/" target="_blank" rel="noopener noreferrer">
              <Image className="imageUniSmall3" src="https://www.bisanziosvelata.it/01_uniroma1_c.png"  />
              </a>
          </Col>
          <Col className="custumCol">
              <a className = "linkUnivB" href="https://www.uniroma3.it/" target="_blank" rel="noopener noreferrer">
              <Image className="imageUniSmall3" src="https://www.bisanziosvelata.it/02_uniroma3.jpg"  />
              </a>
          </Col>
          <Col className="custumCol">
              <a className = "linkUnivB" href="https://www.iulm.it/en" target="_blank" rel="noopener noreferrer">
              <Image className="imageUniSmall3" src="https://www.bisanziosvelata.it/03_iulm.png"  />
              </a>
          </Col>
          <Col className="custumCol">
              <a className = "linkUnivB" href="https://www.unisalento.it/" target="_blank" rel="noopener noreferrer">
              <Image className="imageUniSmall3" src="https://www.bisanziosvelata.it/04_unisa_mod.png"  />
              </a>
          </Col>
        </Row>
      </Col>

      </Row>
    </Container>
    <Footer />
    </>
  );
}

export default ContactPage;
