import React from 'react';
import ArtworkListItem from './ArtworkListItem';
import Col from 'react-bootstrap/Col';
import './artwork.scss';
import Loading from '../Loading/Loading';

const ArtworkList = ({ artworks, currentIndex, setActiveArtwork, loading }) => {
  // Controllo se artworks è vuoto
  const isLoading = loading || !artworks || artworks.length === 0;

  return (
    <Col className="text-left custumListCont">
      {isLoading ? (
        <Loading />
      ) : (
        <ul className="list-group">
          {artworks.map(
            (artwork, index) =>
              artwork && (
                <ArtworkListItem
                  key={index}
                  artwork={artwork}
                  isActive={currentIndex === index}
                  handleClick={() => setActiveArtwork(artwork, index)}
                />
              )
          )}
        </ul>
      )}
    </Col>
  );
};

export default ArtworkList;
