import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import './Compilazione.scss';
import { useLanguage } from '../../../LanguageContext';

const Compilazione = ({ currentArtwork, isArrayEmpty }) => {
  const { selectedLanguage } = useLanguage();
  
  return (
    !isArrayEmpty(currentArtwork.date_of_completion_ids) && (
      <Col className="col-12 col-md-12">        
        <strong>{selectedLanguage === 'it' ? 'Data di compilazione' : 'Date of compilation'}</strong>
        <Col className="col-12 col-md-12 boxField">
          {currentArtwork.date_of_completion_ids
            .sort((a, b) => b.date - a.date)
            .map((date, index) => (
              <span key={index}>
                {date.date} {date.date_note !== false ? `(${date.date_note})` : ''}
                {index < currentArtwork.date_of_completion_ids.length - 1 ? '; ' : ''}
              </span>
            ))}
        </Col>
      </Col>
    )
  );    
};

export default Compilazione;
