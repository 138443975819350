import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useLanguage } from '../../LanguageContext';
import emailjs from '@emailjs/browser';

function ContactForm() {
    const { selectedLanguage } = useLanguage();
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      message: ''
    });
  
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
    
      // Invia l'email tramite EmailJS
      emailjs.send(
        'service_9ha5g6n', // Sostituisci con il tuo service ID
        'template_qytezyc', // Sostituisci con il tuo template ID
        {
          name: formData.name,
          email: formData.email,
          message: formData.message,
        },
        '4nsPBt7TGlek5ffaa' // Sostituisci con la tua Public Key
      )
      .then(() => {
        alert(selectedLanguage === 'it'
          ? 'Grazie per averci contattato! Ti risponderemo al più presto.'
          : 'Thank you for contacting us! We will get back to you as soon as possible.');
    
        // Reset the form data
        setFormData({
          name: '',
          email: '',
          message: ''
        });
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        alert(selectedLanguage === 'it'
          ? 'Si è verificato un errore durante l\'invio del messaggio. Riprova più tardi.'
          : 'An error occurred while sending the message. Please try again later.');
      });
    };
    
    
    return (
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicName">
          <Form.Label>{selectedLanguage === 'it' ? 'Nome e Cognome' : 'Full Name'}</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            placeholder={selectedLanguage === 'it' ? 'Nome e Cognome' : 'Full Name'}
            required
          />
        </Form.Group>
  
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>{selectedLanguage === 'it' ? 'Indirizzo email' : 'Email Address'}</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder={selectedLanguage === 'it' ? 'Email' : 'Email'}
            required
          />
        </Form.Group>
  
        <Form.Group className="mb-3" controlId="formBasicMessage">
          <Form.Label>{selectedLanguage === 'it' ? 'Messaggio' : 'Message'}</Form.Label>
          <Form.Control
            as="textarea"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            placeholder={selectedLanguage === 'it' ? 'Messaggio' : 'Message'}
            rows={3}
            required
          />
        </Form.Group>
  
        <Button variant="primary" type="submit" style={{background:"#7f163d", borderColor:"#7f163d"}}>
          {selectedLanguage === 'it' ? 'Invia' : 'Send'}
        </Button>
      </Form>
    );
  }

export default ContactForm;
