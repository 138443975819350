  import React, { useRef } from 'react';

  import Container from 'react-bootstrap/Container';
  import Row from 'react-bootstrap/Row';
  import Col from 'react-bootstrap/Col';

  import './researchpage.scss';

  import CarouselPages from '../../components/CarouselPages/CarouselPages';
  import { useLanguage } from '../../LanguageContext';
  import Footer from '../../components/Footer/Footer';
  import { Helmet } from 'react-helmet';
  import ArtworksListPage from '../ArtworkListPage/ArtworkListPage';
  
  function ResearchPage() {
    const { selectedLanguage } = useLanguage();
    const rowRef = useRef(null); // Riferimento alla riga successiva
   
    return (
      <>
      <Helmet>
        {/* Titolo */}
        <title>Bisanzio Svelata - Ricerca</title>

        {/* Meta Tags */}
        <meta name="description" content="Esplora i segreti di Bisanzio con Bisanzio Svelata - un progetto di ricerca dedicato a svelare i misteri dell'Impero Bizantino." />
        <meta name="keywords" content="Bisanzio, Bisanzio Svelata, progetto di ricerca, storia, misteri, Impero Bizantino" />

        {/* Open Graph / Facebook */}
        <meta property="og:title" content="Bisanzio Svelata - Ricerca" />
        <meta property="og:description" content="Esplora i segreti di Bisanzio con Bisanzio Svelata - un progetto di ricerca dedicato a svelare i misteri dell'Impero Bizantino." />
        <meta property="og:image" content="https://www.bisanziosvelata.it/bisanziosvelata.png" />
        <meta property="og:url" content="https://www.bisanziosvelata.it/research" />
        <meta property="og:type" content="website" />

        {/* Twitter */}
        <meta property="twitter:title" content="Bisanzio Svelata - Ricerca" />
        <meta property="twitter:description" content="Esplora i segreti di Bisanzio con Bisanzio Svelata - un progetto di ricerca dedicato a svelare i misteri dell'Impero Bizantino." />
        <meta property="twitter:image" content="https://www.bisanziosvelata.it/bisanziosvelata.png" />
        <meta property="twitter:url" content="https://www.bisanziosvelata.it/research" />

        {/* URL Canonico */}
        <link rel="canonical" href="https://www.bisanziosvelata.it/research" />
      </Helmet>
      
      <Container fluid className="bg-container">
        
        <Row className="justify-content-center align-items-center rowTitleO">


          <Col md={12} sx={12} lg={12} className='homeScritta'>
            
            <Col md={12} sx={12} lg={12}>
              <h1 className="titleO">
                {selectedLanguage === 'it' ? 'Ricerca' : 'Research' }
              </h1>
              <h2 className="projectBriefDesc" style={{ textAlign: 'justify' }}>
                {selectedLanguage === 'it'
                  ? "Da questa pagina è possibile accedere alle schede relative agli oggetti inclusi nel database. Le maschere di ricerca consentono inoltre di effettuare indagini mirate in base al nome delle opere, al loro luogo di conservazione, alla loro datazione e ai soggetti iconografici rappresentati. Una volta selezionato  l’oggetto nel box a sinistra, cliccando sul tasto “Approfondisci” si aprirà la pagina contenente la scheda di presentazione dell’opera. Per informazioni più dettagliate, all’interno della scheda selezionare “mostra scheda estesa”."
                  : "From this page, you can access the records for the objects included in the database. The search filters help you to find more specific results based on the name of the objects, their current location, their dating, or their iconographic subjects. After selecting an object from the box on the left, click the “Learn More” button to open a new page containing its record. For more detailed information, within the record select “show extended record”."}
              </h2>
              
            </Col>
            {/* <Col md={4} sx={4} lg={4}>
              <CarouselPages />
            </Col> */}
          </Col>
        </Row>
  
        <div ref={rowRef}>
          <Row className="justify-content-center align-items-center">
              <ArtworksListPage />
          </Row>
        </div>
      </Container>
      <Footer />
      </>
    );
  }
  
  export default ResearchPage;
    